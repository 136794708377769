import styled from 'styled-components'

const WhereToInvestSectionStyle = styled.section`
    --wtiTitleMarginBottom: 0px;

    padding: var(--distanceL2) 0;

    .wti-top-cont {
        margin-bottom: var(--distanceM2);

        .wti-title {
            margin-bottom: var(--wtiTitleMarginBottom);
        }
    }

    .wti-sectors {
        .row {
            display: flex;
            align-items: stretch;
            margin-bottom: calc(-1 * var(--distanceS1));

            .wti-item {
                padding-bottom: var(--distanceS1);
            }
        }
    }

    @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXSMin}) {
        --wtiTitleMarginBottom: var(--sp3x);

        .wti-top-cont {
            text-align: center;
        }
    }
`

export default WhereToInvestSectionStyle