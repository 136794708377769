import { memo as Memo, useState, useRef, useEffect } from 'react'
import gsap from 'gsap'

//* Components
import Image from 'components/common/Image'
import Video from 'components/common/Video'
import Text from 'components/common/Text'
import CustomLink from 'components/common/CustomLink'
import Icon from 'components/common/Icon'

//* Styles
import HeroSectionStyle from './style'

const HeroSection = Memo(({ title, file, additionalInfo }) => {

    //! States
    const [showAdInfo, setShowAdInfo] = useState(true)

    //! Refs
    const fileRef = useRef()
    const infoContRef = useRef()
    const adRef = useRef()

    //! Did Mount / Animation
    useEffect(() => {
        if (fileRef.current.contRef) {
            gsap.to([fileRef.current.contRef, infoContRef.current], { y: 0, opacity: 1, delay: 0.5, duration: 1 }).then(() => {
                gsap.to(adRef.current, { opacity: 1, delay: 0.3 })
            })
        }
    }, [])

    return (
        <HeroSectionStyle>
            {
                file?.type === 'image' ? (
                    <Image src={file.src} alt={file.alt} ref={fileRef} />
                ) : (
                    <Video src={file.src} ref={fileRef} autoPlay loop />
                )
            }

            <div className="hs-info-cont" ref={infoContRef}>
                <Text tag="h1" className="hs-title h1 white font-montserrat-medium">
                    {title}
                </Text>
            </div>

            {(additionalInfo?.url && showAdInfo) ? (
                <div className="hs-ad-cont" ref={adRef}>
                    <CustomLink
                        url={additionalInfo.url}
                        className="hs-ad-info-cont white-bg borderRadiusM"
                        target="_blank"
                        ariaLabel="noopener noreferer"
                    >
                        <Text className="hs-ad-info p p-1 font-montserrat">
                            {additionalInfo?.title}
                        </Text>

                        <Icon name="button-arrow" className="hs-ad-info-icon h5" />
                    </CustomLink >

                    <span className="hs-ad-close-cont white-bg" onClick={() => setShowAdInfo(false)}>
                        <Icon name="cross" className="hs-ad-close-icon" />
                    </span>
                </div>

            ) : null}

        </HeroSectionStyle>
    )
})

export default HeroSection