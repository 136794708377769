import { memo as Memo, useMemo } from 'react'

//* HOC
import withDataContext from 'context/consumerHOC/DataConsumer'

//* Components
import Page from 'components/common/Page'
import HeroSection from 'components/pages/home/HeroSection'
import AboutSection from 'components/pages/home/AboutSection'
import WhyArmeniaSection from 'components/pages/home/WhyArmeniaSection'
import WhereToInvestSection from 'components/pages/home/WhereToInvestSection'
import NewsSection from 'components/sections/NewsSection'
import TestimonialsSection from 'components/pages/home/TestimonialsSection'
import HelpSection from 'components/pages/home/HelpSection'
import PartnersSection from 'components/sections/PartnersSection'
import BookingSection from 'components/sections/BookingSection'

const Home = Memo(({ pages, pageParams, global, selectedLang }) => {
    //! Global Data
    const globalData = useMemo(() => global?.[selectedLang], [global, selectedLang])

    //! Page Data
    const pageData = useMemo(() => pages?.[pageParams.name]?.[selectedLang], [pages, selectedLang, pageParams.name])

    return (
        <Page
            className={'home'}
            meta={pageData?.content?.meta}
        >
            {pageData && (
                <>
                    <HeroSection
                        title={pageData.content.hero.title}
                        file={pageData.content.hero.file}
                        additionalInfo={globalData?.notification}
                    />

                    <AboutSection {...pageData.content.about} />

                    <WhyArmeniaSection {...pageData.content.why_armenia} />

                    <WhereToInvestSection {...pageData.content.sector} />

                    {pageData.content.news.items && pageData.content.news.items.length > 0 ? (
                        <NewsSection
                            hasButton={true}
                            {...pageData.content.news}
                        />
                    ) : null}

                    {pageData.content.testimonial.items && pageData.content.testimonial.items.length > 0 ? <TestimonialsSection {...pageData.content.testimonial} /> : null}

                    <HelpSection {...pageData.content.help} />

                    <BookingSection isLast />

                    {pageData.content.partner.items && pageData.content.partner.items.length > 0 ? <PartnersSection {...pageData.content.partner} /> : null}
                </>
            )}
        </Page>
    )
})

export default withDataContext(Home, ['pages', 'global'])
