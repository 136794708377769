import styled from 'styled-components'

const WhyArmeniaSectionStyle = styled.section`
    --wasInfoContMaxWidth: 52.42vw;
    --wasTitleMarginBottom: var(--sp5x);
    --wasTextMarginBottom: var(--sp13x);
    --wasPointPadding: var(--sp8x);
    --wasPointIconSize: var(--sp13x);
    --wasPointIconMarginBottom: var(--sp10x);
    --wasPointTitleMarginBottom: var(--distanceL3);

    margin: var(--distanceL1) 0;

    .was-info-cont {
        max-width: var(--wasInfoContMaxWidth);
        margin: 0 auto;
        text-align: center;

        .was-title {
            margin-bottom: var(--wasTitleMarginBottom);
        }

        .was-text {
            margin-bottom: var(--wasTextMarginBottom);
        }
    }

    .was-point-col {

        .was-point-cont {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: var(--wasPointPadding);
            background-color: transparent;
            transition: all var(--trTime);

            .lottie-container {
                display: inline-block;
                margin-bottom: var(--wasPointIconMarginBottom);
                width: var(--wasPointIconSize);

                path {
                    fill: var(--white);
                    stroke: var(--green100);
                }
            }

            .was-point-title {
                flex: 1;
                margin-bottom: var(--wasPointTitleMarginBottom);
                color: var(--textBlack100);
                transition: color var(--trTime);
            }

            .was-point-link-icon {
                display: inline-flex;
                align-self: flex-end;
                color: var(--textBlack100);
                transition: color var(--trTime);
            }

            @media (hover: hover) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
                &:hover {
                    background-color: var(--red100);

                    .was-point-title,
                    .was-point-link-icon {
                        color: var(--white);
                    }

                    .lottie-container {
                        path {
                            fill: var(--red100);
                            stroke: var(--white);
                        }
                    }
                }
            }
        }
    }

    //! -> Borders
    @media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		.was-point-col {
            &:not(:last-child) {
                border-right: 1px solid var(--textBlack20);
            }
        }
	}

    @media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		.was-point-col {
            &:not(:nth-child(2n)) {
                border-right: 1px solid var(--textBlack20);
            }

            &:not(:nth-last-child(1)):not(:nth-last-child(2)) {
                border-bottom: 1px solid var(--textBlack20);
            }
        }
	}

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		.was-point-col {

            &:not(:last-child) {
                border-bottom: 1px solid var(--textBlack20);
            }
        }
	}
    //! -> End borders

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--wasInfoContMaxWidth: 51.979vw;
        --wasTitleMarginBottom: var(--sp4x);
        --wasTextMarginBottom: var(--sp10x);
        --wasPointPadding: var(--sp7x);
        --wasPointIconSize: var(--sp9x);
        --wasPointIconMarginBottom: var(--sp9x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--wasInfoContMaxWidth: 58.145vw;
        --wasTitleMarginBottom: var(--sp4x);
        --wasTextMarginBottom: var(--sp7x);
        --wasPointPadding: var(--sp5x);
        --wasPointIconSize: var(--sp7x);
        --wasPointIconMarginBottom: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
        --wasInfoContMaxWidth: 58.472vw;
        --wasTitleMarginBottom: var(--sp4x);
        --wasTextMarginBottom: var(--sp6x);
        --wasPointPadding: var(--sp4x);
        --wasPointIconSize: var(--sp6x);
        --wasPointIconMarginBottom: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--wasInfoContMaxWidth: 59.375vw;
        --wasTitleMarginBottom: var(--sp3x);
        --wasTextMarginBottom: var(--sp6x);
        --wasPointPadding: var(--sp3x);
        --wasPointIconSize: var(--sp5x);
        --wasPointIconMarginBottom: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--wasInfoContMaxWidth: 75.12vw;
        --wasTitleMarginBottom: var(--sp3x);
        --wasTextMarginBottom: var(--sp6x);
        --wasPointPadding: var(--sp2x) var(--sp1x);
        --wasPointIconSize: var(--sp5x);
        --wasPointIconMarginBottom: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--wasInfoContMaxWidth: 100%;
        --wasTitleMarginBottom: var(--sp3x);
        --wasTextMarginBottom: var(--sp6x);
        --wasPointPadding: var(--sp3x);
        --wasPointIconSize: var(--sp6x);
        --wasPointIconMarginBottom: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--wasInfoContMaxWidth: 100%;
        --wasTitleMarginBottom: var(--sp3x);
        --wasTextMarginBottom: var(--sp5x);
        --wasPointPadding: var(--sp3x);
        --wasPointIconSize: var(--sp6x);
        --wasPointIconMarginBottom: var(--sp4x);
    }
`

export default WhyArmeniaSectionStyle