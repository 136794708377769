import { memo as Memo } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'

//* Components
import Container from 'components/common/Container'
import Text from 'components/common/Text'
import Image from 'components/common/Image'
import Icon from 'components/common/Icon'

//* Styles
import TestimonialsSectionStyle from './style'

const TestimonialsSection = Memo(({ title, items }) => {
    return (
        <TestimonialsSectionStyle className="bgGray-bg">
            <Container>
                <Container full row>
                    <div className="col-6 col-t-12">
                        <Text tag="h2" className="ts-title h2 font-montserrat ">{title}</Text>
                    </div>
                </Container>

                <div className="ts-slider-cont">

                    <Swiper
                        modules={[Navigation]}
                        slidesPerView={'auto'}
                        navigation={{
                            prevEl: '.ts-left-btn',
                            nextEl: '.ts-right-btn',
                        }}
                    >
                        {items.map((i, k) => (
                            <SwiperSlide key={k} className="borderRadiusL white-bg">
                                <div className="ts-slide-inner">
                                    <div className="ts-info-cont">

                                        <Text className="ts-text p p-2 font-montserrat" >{i.text}</Text>

                                        <Text className="ts-name p p-1 font-montserrat-semibold" >{`${i.name}, ${i.position}`}</Text>

                                        <Image className="ts-logo" {...i.logo} />

                                    </div>

                                    <div className="ts-photo">
                                        <Image className="borderRadiusM" {...i.photo} />
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    <div className="ts-buttons">
                        <Icon name="circle-arrow-left" className="ts-btn ts-left-btn" />
                        <Icon name="circle-arrow-right" className="ts-btn ts-right-btn" />
                    </div>

                </div>
            </Container>
        </TestimonialsSectionStyle>
    )
})

export default TestimonialsSection