import styled from 'styled-components'

const TestimonialsSectionStyle = styled.section`
	--tsSlideWidth: 52.38vw;
	--tsSlideMarginH: var(--sp5x);
	--tsSlidePadding: var(--sp8x);
	--tsLogoHeight: var(--sp6x);
	--tsNameMarginV: var(--sp6x);
	--tsPhotoWidth: 16.171vw;
	--tsPhotoMarginLeft: var(--sp5x);
	--tsBtnSize: var(--sp11x);
	--tsBtnMarginH: var(--sp4x);

	--tsPhotoMarginBottom: 0;
	--tsButtonsMarginTop: 0;
	--tsButtonsPosition: absolute;
	--tsButtonsInset: auto 0 100% auto;

	padding: var(--distanceL2) 0;
	overflow: hidden;

	.ts-slider-cont {
		display: flex;
		flex-direction: column;
		position: relative;
		padding-top: var(--distanceM2);

		.swiper {
			width: 100%;
			overflow: visible;
			display: flex;
			height: 100%;

			.swiper-wrapper {
				height: unset !important;

				.swiper-slide {
					width: var(--tsSlideWidth);
					padding: var(--tsSlidePadding);
					overflow: hidden;

					&:not(:last-child) {
						margin: 0 calc(var(--tsSlideMarginH)) 0 0;
					}

					.ts-slide-inner {
						display: flex;

						.ts-info-cont {
							display: flex;
							flex-direction: column;
							flex: 1;

							.ts-text {
								flex: 1;
							}

							.ts-name {
								margin: var(--tsNameMarginV) 0;
							}

							.ts-logo {
								--proportion: 0;

								width: auto;
								height: var(--tsLogoHeight);

								img {
									position: relative !important;
									width: unset !important;
									object-fit: contain;
								}
							}
						}

						.ts-photo {
							width: var(--tsPhotoWidth);
							margin-left: var(--tsPhotoMarginLeft);
							margin-bottom: var(--tsPhotoMarginBottom);

							.image-cont {
								--proportion: 121.98%;

								overflow: hidden;
							}
						}
					}
				}
			}
		}

		.ts-buttons {
			display: flex;
			align-items: center;
			position: var(--tsButtonsPosition);
			inset: var(--tsButtonsInset);
			margin: var(--tsButtonsMarginTop) calc(var(--tsBtnMarginH) / -2) 0;
			align-self: flex-end;

			.ts-btn {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				margin: 0 calc(var(--tsBtnMarginH) / 2);
				color: var(--textBlack100);
				transition: all var(--trTime);
				border-radius: 50%;
				overflow: hidden;
				cursor: pointer;

				&:before {
					font-size: var(--tsBtnSize);
				}

				&.swiper-button-disabled {
					opacity: 0.5;
					pointer-events: none;
				}

				&.swiper-button-lock {
					display: none !important;
				}

				@media (hover: hover) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
					&:hover {
						color: var(--red100);
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--tsSlideWidth: 52.18vw;
		--tsSlideMarginH: var(--sp4x);
		--tsSlidePadding: var(--sp6x);
		--tsLogoHeight: var(--sp6x);
		--tsNameMarginV: var(--sp5x);
		--tsPhotoWidth: 16.04vw;
		--tsPhotoMarginLeft: var(--sp4x);
		--tsBtnSize: var(--sp9x);
		--tsBtnMarginH: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--tsSlideWidth: 58.21vw;
		--tsSlideMarginH: var(--sp4x);
		--tsSlidePadding: var(--sp5x);
		--tsLogoHeight: var(--sp5x);
		--tsNameMarginV: var(--sp4x);
		--tsPhotoWidth: 17.88vw;
		--tsPhotoMarginLeft: var(--sp4x);
		--tsBtnSize: var(--sp7x);
		--tsBtnMarginH: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--tsSlideWidth: 58.54vw;
		--tsSlideMarginH: var(--sp4x);
		--tsSlidePadding: var(--sp5x);
		--tsLogoHeight: var(--sp4x);
		--tsNameMarginV: var(--sp3x);
		--tsPhotoWidth: 17.84vw;
		--tsPhotoMarginLeft: var(--sp4x);
		--tsBtnSize: var(--sp6x);
		--tsBtnMarginH: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--tsSlideWidth: 59.375vw;
		--tsSlideMarginH: var(--sp4x);
		--tsSlidePadding: var(--sp3x);
		--tsLogoHeight: var(--sp4x);
		--tsNameMarginV: var(--sp2x);
		--tsPhotoWidth: 19.218vw;
		--tsPhotoMarginLeft: var(--sp3x);
		--tsBtnSize: var(--sp5x);
		--tsBtnMarginH: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--tsSlideWidth: 82.91vw;
		--tsSlideMarginH: var(--sp3x);
		--tsSlidePadding: var(--sp3x);
		--tsLogoHeight: var(--sp4x);
		--tsNameMarginV: var(--sp3x);
		--tsPhotoWidth: 26.367vw;
		--tsPhotoMarginLeft: var(--sp3x);
		--tsBtnSize: var(--sp5x);
		--tsBtnMarginH: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--tsSlideWidth: 100%;
		--tsSlideMarginH: var(--sp3x);
		--tsSlidePadding: var(--sp3x);
		--tsLogoHeight: var(--sp4x);
		--tsNameMarginV: var(--sp2x);
		--tsPhotoWidth: 23.95vw;
		--tsPhotoMarginLeft: var(--sp3x);
		--tsBtnSize: var(--sp5x);
		--tsBtnMarginH: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--tsSlideWidth: 100%;
		--tsSlideMarginH: var(--sp3x);
		--tsSlidePadding: var(--sp3x);
		--tsLogoHeight: var(--sp4x);
		--tsNameMarginV: var(--sp2x);
		--tsPhotoWidth: 40.338vw;
		--tsPhotoMarginLeft: 0;
		--tsBtnSize: var(--sp6x);
		--tsBtnMarginH: var(--sp2x);

		--tsPhotoMarginBottom: var(--sp3x);
		--tsButtonsMarginTop: var(--sp5x);
		--tsButtonsPosition: relative;
		--tsButtonsInset: auto;

		.ts-slide-inner {
			flex-direction: column-reverse;
		}
	}
`

export default TestimonialsSectionStyle
