import styled from 'styled-components'

const AboutSectionStyle = styled.section`
    --asTitleMaxWidth: 80%;
    --asTitleMarginBottom: 0;

    padding: var(--distanceL3) 0;

    .as-title {
        max-width: var(--asTitleMaxWidth);
        margin-bottom: var(--asTitleMarginBottom);
    }

    @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeMin}) {
        --asTitleMaxWidth: 100%;
        --asTitleMarginBottom: var(--sp3x);

        text-align: center;
    }
`

export default AboutSectionStyle