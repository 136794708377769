import styled from 'styled-components'

const HelpSectionStyle = styled.section`
    --helsTitleMarginBottom: var(--sp5x);
    --helsColPaddingH: var(--sp5x);
    --helItemPaddingV: var(--sp10x);
    --helsItemTitleMarginBottom: var(--sp2x);

    --tsButtonsMarginTop: 0;
    --helsButtonPosition: absolute;
    --helsButtonInset: auto 0 100% auto;

    padding: var(--distanceL2) 0;

    .hels-title {
        margin-bottom: var(--helsTitleMarginBottom);
    }

    .hels-items-cont {
        --colPadding: var(--helsColPaddingH);

        position: relative;
        padding-top: var(--distanceM2);

        .row {
            align-items: stretch;

            .hels-col {
                position: relative;

                .hels-item {
                    display: flex;

                    &-number {
                        margin-right: var(--helsColPaddingH);
                    }

                    &-info {
                        .hels-item-title {
                            margin-bottom: var(--helsItemTitleMarginBottom);
                        }

                        .hels-item-text {
                            
                        }
                    }
                }
            }
        }

        .hels-button-cont {
            display: flex;
            justify-content: flex-end;

            .hels-button {
                position: var(--helsButtonPosition);
                inset: var(--helsButtonInset);
                margin-top: var(--tsButtonsMarginTop);
            }
        }
        
    }

    //! Items padding and border
    @media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSize}) {

        .hels-col {
            &:nth-child(1){
                &:before {
                    content: '';
                    position: absolute;
                    top: 100%;
                    right: 0%;
                    transform: translate3d(0, -50%, 0);
                    width: 0;
                    height: 1px;
                    background: var(--green60);
                    transition: all calc(2 * var(--trTime)) var(--trTime);
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0%;
                    right: 0%;
                    transform: translate3d(50%, 0, 0);
                    width: 1px;
                    height: 0;
                    background: var(--green60);
                    transition: all calc(2 * var(--trTime)) var(--trTime);
                }
            }

            &:nth-child(4) {
                &:before {
                    content: '';
                    position: absolute;
                    bottom: 100%;
                    left: 0%;
                    transform: translate3d(0, 50%, 0);
                    width: 0;
                    height: 1px;
                    background: var(--green60);
                    transition: all calc(2 * var(--trTime)) var(--trTime);
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 0%;
                    left: 0%;
                    transform: translate3d(-50%, 0, 0);
                    width: 1px;
                    height: 0;
                    background: var(--green60);
                    transition: all calc(2 * var(--trTime)) var(--trTime);
                }
            }

            &:nth-child(1),
            &:nth-child(2) {
                padding-bottom: var(--helItemPaddingV);
            }

            &:nth-child(3),
            &:nth-child(4) {
                padding-top: var(--helItemPaddingV);
            }
        }

        &.hels-active-section {
            .hels-col {
                &:nth-child(1){
                    &:before {
                        width: calc(100% - var(--colPadding));
                    }

                    &:after {
                        height: 100%;
                    }
                }

                &:nth-child(4) {
                    &:before {
                        width: calc(100% - var(--colPadding));
                    }

                    &:after {
                        height: 100%;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeMin}) {
        .hels-col {
            &:not(:nth-child(4)) {
                margin-bottom: var(--helItemPaddingV);
                padding-bottom: var(--helItemPaddingV);
                /* border-bottom: 1px solid var(--green60); */

                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    transform: translate3d(0, -50%, 0);
                    width: 0;
                    height: 1px;
                    background: var(--green60);
                    transition: all calc(2 * var(--trTime)) var(--trTime);
                }
            }
        }

        &.hels-active-section {
            .hels-col {
                &:not(:nth-last-child(4)) {

                    &:before {
                        width: calc(100% - var(--colPadding));
                    }
                }
            }
        }
    }

    @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
        .hels-col {
            &:not(:nth-child(4)) {
                &:before {
                    left: var(--colPadding);
                }
            }
        }

        &.hels-active-section {
            .hels-col {
                &:not(:nth-child(4)) {

                    &:before {
                        width: calc(100% - (2 * var(--colPadding)));
                    }
                }
            }
        }
    }
    //! End items padding and border

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--helsTitleMarginBottom: var(--sp4x);
        --helsColPaddingH: var(--sp6x);
        --helItemPaddingV: var(--sp8x);
        --helsItemTitleMarginBottom: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--helsTitleMarginBottom: var(--sp4x);
        --helsColPaddingH: var(--sp4x);
        --helItemPaddingV: var(--sp6x);
        --helsItemTitleMarginBottom: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
        --helsTitleMarginBottom: var(--sp4x);
        --helsColPaddingH: var(--sp4x);
        --helItemPaddingV: var(--sp6x);
        --helsItemTitleMarginBottom: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--helsTitleMarginBottom: var(--sp3x);
        --helsColPaddingH: var(--sp3x);
        --helItemPaddingV: var(--sp5x);
        --helsItemTitleMarginBottom: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--helsTitleMarginBottom: var(--sp3x);
        --helsColPaddingH: var(--sp3x);
        --helItemPaddingV: var(--sp4x);
        --helsItemTitleMarginBottom: var(--sp2x);

        --tsButtonsMarginTop: var(--sp6x);
        --helsButtonPosition: relative;
        --helsButtonInset: auto;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
        --helsTitleMarginBottom: var(--sp3x);
        --helsColPaddingH: var(--sp2x);
        --helItemPaddingV: var(--sp3x);
        --helsItemTitleMarginBottom: var(--sp2x);

        --tsButtonsMarginTop: var(--sp6x);
        --helsButtonPosition: relative;
        --helsButtonInset: auto;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--helsTitleMarginBottom: var(--sp3x);
        --helsColPaddingH: var(--sp2x);
        --helItemPaddingV: var(--sp2-5x);
        --helsItemTitleMarginBottom: var(--sp2x);

        --tsButtonsMarginTop: var(--sp5x);
        --helsButtonPosition: relative;
        --helsButtonInset: auto;

        .hels-info-cont {
            text-align: center;
        }

        .hels-button-cont {
            width: 100%;

            .hels-button {
                width: 100%;
            }
        }
    }
`

export default HelpSectionStyle