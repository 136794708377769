import { memo as Memo, useMemo } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'

//* HOC
import withUIContext from 'context/consumerHOC/UIConsumer'

//* Components
import Container from 'components/common/Container'
import Text from 'components/common/Text'
import Image from 'components/common/Image'
import Icon from 'components/common/Icon'
import CustomLink from 'components/common/CustomLink'

//* Styles
import PartnersSectionStyle from './style'

const PartnersSection = Memo(({ title, items, winWidth, sectionRef, unsetPaddingTop }) => {
    //! Slider Items
    const sliderItems = useMemo(() => {
        const itemRowCount = winWidth < 768 ? 2 : 1
        const itemsArr = []
        const linkProps = {
            url: '',
            ariaLabel: 'noopener noreferer',
            target: '_blank',
        }

        for (let i = 0; i < items.length; i += itemRowCount) {
            const El = items[i].link ? CustomLink : `div`
            const elProps = items[i].link ? { ...linkProps, url: items[i].link } : {}

            let El2, el2Props

            if (itemRowCount === 2 && items[i + 1]) {
                El2 = itemRowCount === 2 && items[i + 1].link ? CustomLink : `div`
                el2Props = items[i + 1].link ? { ...linkProps, url: items[i + 1].link } : {}
            }

            itemsArr.push(
                <SwiperSlide
                    key={`${itemRowCount}-${i}`}
                    className='pas-slide'
                >
                    <El
                        {...elProps}
                        className={`pas-slide-inner ${itemRowCount === 2 ? 'pas-first-row' : ''}`}
                    >
                        <div className='pas-slide-image'>
                            <Image {...items[i].logo} />
                        </div>
                    </El>

                    {itemRowCount === 2 && items[i + 1] ? (
                        <El2
                            {...el2Props}
                            className='pas-slide-inner'
                        >
                            <div className='pas-slide-image'>
                                <Image {...items[i + 1].logo} />
                            </div>
                        </El2>
                    ) : (
                        ''
                    )}
                </SwiperSlide>
            )
        }

        return itemsArr
    }, [items, winWidth])

    return (
        <PartnersSectionStyle unsetPaddingTop={unsetPaddingTop} ref={sectionRef}>
            <Container>
                <Container
                    full
                    row
                >
                    <div className='col-6 col-m-12'>
                        <Text
                            tag='h2'
                            className='pas-title h2 font-montserrat '
                        >
                            {title}
                        </Text>
                    </div>
                </Container>

                <div className='pas-slider-cont'>
                    <Swiper
                        modules={[Navigation]}
                        slidesPerView={'auto'}
                        navigation={{
                            prevEl: '.pas-left-btn',
                            nextEl: '.pas-right-btn',
                        }}
                    >
                        {sliderItems}
                    </Swiper>

                    <div className='pas-buttons'>
                        <Icon
                            name='circle-arrow-left'
                            className='pas-btn pas-left-btn'
                        />
                        <Icon
                            name='circle-arrow-right'
                            className='pas-btn pas-right-btn'
                        />
                    </div>
                </div>
            </Container>
        </PartnersSectionStyle>
    )
})

export default withUIContext(PartnersSection, ['winWidth'])
