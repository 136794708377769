import { memo as Memo, useMemo } from 'react'

//* Components
import Container from 'components/common/Container'
import Text from 'components/common/Text'
import CustomLink from 'components/common/CustomLink'
import Icon from 'components/common/Icon'
import LottieAnimation from 'components/common/LottieAnimation'

//* JSON's
import was1 from 'lottieFiles/was1.json'
import was2 from 'lottieFiles/was2.json'
import was3 from 'lottieFiles/was3.json'
import was4 from 'lottieFiles/was4.json'

//* Styles
import WhyArmeniaSectionStyle from './style'

const WhyArmeniaSection = Memo(({ title, text, points }) => {
    //! Icons
    const icons = useMemo(() => ([was1, was2, was3, was4]), [])

    return (
        <WhyArmeniaSectionStyle>
            <Container>
                <div className="was-info-cont">
                    <Text tag="h2" className="was-title h2 font-montserrat ">{title}</Text>

                    <Text tag="h3" className="was-text h6 font-montserrat ">{text}</Text>
                </div>

                <Container full row>
                    {points.map((p, k) => (
                        <div key={k} className="was-point-col col-3 col-ts-6 col-m-12">
                            <CustomLink url={p.additional_info.url} rel="noopener noreferer" className="was-point-cont borderRadiusM">

                                <LottieAnimation animData={icons[k]} autoplay loop />

                                <Text className="was-point-title">{p.text}</Text>

                                <Icon name={'button-arrow'} className="was-point-link-icon h4" />
                            </CustomLink>
                        </div>
                    ))}
                </Container>
            </Container>
        </WhyArmeniaSectionStyle >
    )
})

export default WhyArmeniaSection