import styled from 'styled-components'

const PartnersSectionStyle = styled.section`
	--pasSlideWidth: calc(100% / 6);
	--pasBtnSize: var(--sp11x);
	--pasBtnMarginH: var(--sp4x);

	--pasSlidePaddingH: var(--colPadding);
	--pasButtonsMarginTop: 0;
	--pasButtonsPosition: absolute;
	--pasButtonsInset: auto 0 100% auto;

	padding: var(--distanceL1) 0;
	${(props) => (props.unsetPaddingTop ? 'padding-top: unset;' : '')}

	.pas-slider-cont {
		display: flex;
		flex-direction: column;
		position: relative;
		padding-top: var(--distanceM2);

		.swiper {
			width: 100%;

			.swiper-wrapper {
				margin: 0 calc(var(--pasSlidePaddingH) / -2);

				.pas-slide {
					width: var(--pasSlideWidth);
					padding: 0 var(--pasSlidePaddingH);

					.pas-slide-inner {
						width: 100%;
						display: inline-flex;

						.pas-slide-image {
							width: 100%;

							.image-cont {
								--proportion: 70.49%;

								img {
									object-fit: contain;
								}
							}
						}

						&.pas-first-row {
							margin-bottom: var(--sp4x);
						}
					}
				}
			}
		}

		.pas-buttons {
			display: flex;
			align-items: center;
			position: var(--pasButtonsPosition);
			inset: var(--pasButtonsInset);
			margin: var(--pasButtonsMarginTop) calc(var(--pasBtnMarginH) / -2) 0;
			align-self: flex-end;

			.pas-btn {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				margin: 0 calc(var(--pasBtnMarginH) / 2);
				color: var(--textBlack100);
				transition: all var(--trTime);
				border-radius: 50%;
				overflow: hidden;
				cursor: pointer;

				&:before {
					font-size: var(--pasBtnSize);
				}

				&.swiper-button-disabled {
					opacity: 0.5;
					pointer-events: none;
				}

				&.swiper-button-lock {
					display: none !important;
				}

				@media (hover: hover) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
					&:hover {
						color: var(--red100);
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--pasSlideWidth: calc(100% / 6);
		--pasBtnSize: var(--sp9x);
		--pasBtnMarginH: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--pasSlideWidth: calc(100% / 6);
		--pasBtnSize: var(--sp7x);
		--pasBtnMarginH: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--pasSlideWidth: calc(100% / 6);
		--pasBtnSize: var(--sp6x);
		--pasBtnMarginH: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--pasSlideWidth: calc(100% / 6);
		--pasBtnSize: var(--sp5x);
		--pasBtnMarginH: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--pasSlideWidth: calc(100% / 6);
		--pasBtnSize: var(--sp5x);
		--pasBtnMarginH: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--pasSlideWidth: calc(100% / 4);
		--pasBtnSize: var(--sp5x);
		--pasBtnMarginH: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--pasSlideWidth: calc(100% / 3);
		--pasBtnSize: var(--sp6x);
		--pasBtnMarginH: var(--sp2x);

		--pasButtonsMarginTop: var(--sp5x);
		--pasButtonsPosition: relative;
		--pasButtonsInset: auto;
	}
`

export default PartnersSectionStyle
