import { memo as Memo, useMemo } from 'react'

//* HOC's
import withDataContext from 'context/consumerHOC/DataConsumer'
import withLanguageContext from 'context/consumerHOC/LanguageConsumer'

//* Components
import Text from 'components/common/Text'
import Icon from 'components/common/Icon'
import CustomLink from 'components/common/CustomLink'

//* Styles
import BookingSectionStyle from './style'

const BookingSection = Memo(({ global, selectedLang, isLast, isGreenBg = false }) => {
	//! Global Data
	const globalData = useMemo(() => global?.[selectedLang], [global, selectedLang])

	return globalData?.call_to_action ? (
		<BookingSectionStyle className={`booking-section ${isLast ? 'last' : ''}`}>
			<div className='bos-icon-cont bgGray-bg'>
				<Icon
					name='appointment'
					className='bos-icon'
				>
					<span className='path1' />
					<span className='path2' />
					<span className='path3' />
					<span className='path4' />
					<span className='path5' />
					<span className='path6' />
					<span className='path7' />
					<span className='path8' />
					<span className='path9' />
					<span className='path10' />
					<span className='path11' />
					<span className='path12' />
					<span className='path13' />
					<span className='path14' />
					<span className='path15' />
					<span className='path16' />
					<span className='path17' />
					<span className='path18' />
					<span className='path19' />
					<span className='path20' />
					<span className='path21' />
					<span className='path22' />
					<span className='path23' />
					<span className='path24' />
					<span className='path25' />
					<span className='path26' />
					<span className='path27' />
				</Icon>
			</div>

			<div className={`bos-title-cont ${isGreenBg ? 'green-100-bg' : ''}`}>
				<Text className='h6 font-montserrat'>{globalData.call_to_action.title}</Text>
			</div>

			<CustomLink
				target='_blank'
				url={globalData.call_to_action.url}
				className='bos-button h5 font-montserrat'
			>
				{globalData.call_to_action.button_text}
				<Icon name='button-arrow' />
			</CustomLink>
		</BookingSectionStyle>
	) : (
		''
	)
})

export default withLanguageContext(withDataContext(BookingSection, ['global']), ['selectedLang'])
