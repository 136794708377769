import styled from 'styled-components'

const VideoStyle = styled.div`
	--playButtonSizes: var(--sp15x);
	--proportion: 50.5%;

	position: relative;

	&.video-cont {
		position: relative;
		width: 100%;
		padding-top: var(--proportion);

		video {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: center;
			width: 100%;
			height: 100%;
			z-index: 1;
		}

		.play-button {
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: var(--playButtonSizes);
			height: var(--playButtonSizes);
			background-color: var(--white);
			z-index: 100;
			border-radius: 50%;
			cursor: pointer;
			opacity: 1;
			transition: var(--trTime);

			.button-icon {
				font-size: var(--h5);
			}
		}

		&.playing {
			.play-button {
				opacity: 0;
			}
		}

		@media (hover: hover) {
			&:hover {
				.play-button {
					opacity: 1;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--playButtonSizes: var(--sp12x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--playButtonSizes: var(--sp11x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--playButtonSizes: var(--sp11x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--playButtonSizes: var(--sp10x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--playButtonSizes: var(--sp9x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--playButtonSizes: var(--sp9x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--playButtonSizes: var(--sp6x);
	}
`
export default VideoStyle
