import { memo as Memo } from 'react'

//* Components
import CustomLink from 'components/common/CustomLink'
import Text from 'components/common/Text'
import Image from 'components/common/Image'
import Icon from 'components/common/Icon'

//* Styles
import SectorCardStyle from './style'

const SectorCard = Memo(({ title, description, text, number, icon, icon_hover, slug }) => {
    return (
        <SectorCardStyle className='sc-item borderRadiusM'>
            <CustomLink
                className='sc-cont borderRadiusM'
                url={slug}
            >
                <Text className='p p-1 sc-title sc-text-anim font-montserrat-medium'>{title}</Text>

                <div className='sc-info-cont'>
                    <Text className='sc-description sc-opacity-reverse-anim p p-2 font-montserrat'>{description}</Text>

                    <div className='sc-bottom-cont'>
                        <div className='sc-bottom-info sc-opacity-anim'>
                            <Text className='sc-number white h3 font-montserrat-medium'>{number}</Text>
                            <Text className='sc-text white p p-3 font-montserrat'>{text}</Text>
                        </div>

                        <div className='sc-icons-cont'>
                            <div className='sc-images-cont'>
                                <Image
                                    {...icon}
                                    className='sc-image sc-opacity-reverse-anim'
                                />
                                <Image
                                    {...icon_hover}
                                    className='sc-hover-image sc-opacity-anim'
                                />
                            </div>

                            <Icon
                                name={'button-arrow'}
                                className='sc-link-icon h5 white sc-opacity-anim'
                            />
                        </div>
                    </div>
                </div>
            </CustomLink>
        </SectorCardStyle>
    )
})

export default SectorCard
