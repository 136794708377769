import { memo as Memo, useRef } from 'react'

//* Components
import Container from 'components/common/Container'
import Text from 'components/common/Text'
import Button from 'components/common/Button'

//* Config
import { config, useActiveSection } from 'helpers'

//* Styles
import HelpSectionStyle from './style'

const HelpSection = Memo(({ title, text, points, className, buttonText, buttonUrl }) => {
    //! Refs
    const sectionRef = useRef()

    const activeSection = useActiveSection(sectionRef)

    return (
        <HelpSectionStyle
            ref={sectionRef}
            className={`green-100-bg ${activeSection ? 'hels-active-section' : ''} ${className || ''}`}
        >
            <Container>
                <Container
                    full
                    row
                >
                    <div className='hels-info-cont col-6 col-t-12'>
                        <Text
                            tag='h2'
                            className='hels-title h2 font-montserrat '
                        >
                            {title}
                        </Text>
                        <Text
                            tag='h3'
                            className='hels-text p p-1 font-montserrat '
                        >
                            {text}
                        </Text>
                    </div>
                </Container>

                <Container
                    className='hels-items-cont'
                    full
                    row
                >
                    {!!points &&
                        points.map((p, k) => (
                            <div
                                key={k}
                                className='hels-col col-6 col-ts-12 font-montserrat'
                            >
                                <div className='hels-item'>
                                    <Text className='hels-item-number h4'>{`${k + 1}`.padStart(2, 0)}</Text>

                                    <div className='hels-item-info'>
                                        <Text className='hels-item-title h6 font-montserrat-medium'>{p.title}</Text>
                                        <Text className='hels-item-text p p-1 font-montserrat'>{p.text}</Text>
                                    </div>
                                </div>
                            </div>
                        ))}

                    <div className='hels-button-cont col-12'>
                        <Button
                            target={buttonUrl ? '_blank' : ''}
                            url={buttonUrl || config.routes.ourServices.path}
                            text={buttonText || 'ourServices'}
                            className='hels-button'
                            backgroundColor={'white'}
                            color={'green100'}
                            hoverColor={'green100'}
                            hoverBackgroundColor={'chromeGreen'}
                        />
                    </div>
                </Container>
            </Container>
        </HelpSectionStyle>
    )
})

export default HelpSection
