import { memo as Memo } from 'react'

//* Components
import Container from 'components/common/Container'
import Text from 'components/common/Text'

//* Styles
import AboutSectionStyle from './style'

const AboutSection = Memo(({ title, text }) => {
    return (
        <AboutSectionStyle className="bgGray-bg">
            <Container row>
                <div className="col-6 col-ts-12">
                    <Text tag={'h2'} className="as-title h2 font-montserrat">
                        {title}
                    </Text>
                </div>
                <div className="col-6 col-ts-12">
                    <Text tag={'h3'} className="as-text h6 font-montserrat">
                        {text}
                    </Text>
                </div>
            </Container>
        </AboutSectionStyle>
    )
})

export default AboutSection