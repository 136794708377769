import styled from 'styled-components'

const HeroSectionStyle = styled.section`
	--hsProportion: 50.78%;
	--hsInfoContPaddingH: var(--sp11x);
	--hsInfoContBottom: var(--sp6x);
	--hsTitleMaxWidth: 59.25vw;
	--hsAdContInset: auto var(--hsInfoContPaddingH) var(--hsInfoContBottom) auto;
	--hsAdInfoContMaxWidth: 25.42vw;
	--hsAdInfoContPadding: var(--sp6x);
	--hsAdInfoIconMarginLeft: var(--sp5x);
	--hsAdCloseIconContSize: var(--sp7x);
	--hsAdCloseIconMargin: 0 0 0 var(--sp3x);
	--hsAdCloseIconSize: var(--sp3x);

	position: relative;
	padding-top: var(--hsProportion);
	overflow: hidden;

	.hs-info-cont {
		position: absolute;
		left: 0;
		right: 0;
		bottom: var(--hsInfoContBottom);
		padding: 0 var(--hsInfoContPaddingH);
		transform: translate3d(0, 50%, 0);
		opacity: 0;

		.hs-title {
			max-width: var(--hsTitleMaxWidth);
			text-transform: uppercase;
		}
	}

	.hs-ad-cont {
		display: flex;
		position: absolute;
		inset: var(--hsAdContInset);
		opacity: 0;

		.hs-ad-info-cont {
			display: inline-flex;
			align-items: center;
			max-width: var(--hsAdInfoContMaxWidth);
			padding: var(--hsAdInfoContPadding);

			.hs-ad-info {
			}

			.hs-ad-info-icon {
				margin-left: var(--hsAdInfoIconMarginLeft);
			}
		}

		.hs-ad-close-cont {
			display: flex;
			align-items: center;
			justify-content: center;
			width: var(--hsAdCloseIconContSize);
			height: var(--hsAdCloseIconContSize);
			margin: var(--hsAdCloseIconMargin);
			border-radius: 50%;
			cursor: pointer;

			.hs-ad-close-icon {
				font-size: var(--hsAdCloseIconSize);
			}
		}
	}

	.image-cont,
	.video-cont {
		--proportion: 0;

		position: absolute !important;
		top: 0;
        left: 0;
        right: 0;
        bottom: 0;
		z-index: 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--hsProportion: 50.52%;
		--hsInfoContPaddingH: var(--sp8x);
		--hsInfoContBottom: var(--sp8x);
		--hsTitleMaxWidth: 59.32vw;
		--hsAdContInset: auto var(--hsInfoContPaddingH) var(--hsInfoContBottom) auto;
		--hsAdInfoContMaxWidth: 25.26vw;
		--hsAdInfoContPadding: var(--sp5x);
		--hsAdInfoIconMarginLeft: var(--sp5x);
		--hsAdCloseIconContSize: var(--sp5x);
		--hsAdCloseIconMargin: 0 0 0 var(--sp2x);
		--hsAdCloseIconSize: var(--sp2-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--hsProportion: 58.6%;
		--hsInfoContPaddingH: var(--sp6x);
		--hsInfoContBottom: var(--sp5x);
		--hsTitleMaxWidth: 60.86vw;
		--hsAdContInset: auto var(--hsInfoContPaddingH) var(--hsInfoContBottom) auto;
		--hsAdInfoContMaxWidth: 27.97vw;
		--hsAdInfoContPadding: var(--sp4x);
		--hsAdInfoIconMarginLeft: var(--sp4x);
		--hsAdCloseIconContSize: var(--sp5x);
		--hsAdCloseIconMargin: 0 0 0 var(--sp2x);
		--hsAdCloseIconSize: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--hsProportion: 53.33%;
		--hsInfoContPaddingH: var(--sp6x);
		--hsInfoContBottom: var(--sp6x);
		--hsTitleMaxWidth: 53.125vw;
		--hsAdContInset: auto var(--hsInfoContPaddingH) var(--hsInfoContBottom) auto;
		--hsAdInfoContMaxWidth: 28.19vw;
		--hsAdInfoContPadding: var(--sp4x);
		--hsAdInfoIconMarginLeft: var(--sp4x);
		--hsAdCloseIconContSize: var(--sp5x);
		--hsAdCloseIconMargin: 0 0 0 var(--sp2x);
		--hsAdCloseIconSize: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--hsProportion: 59.375%;
		--hsInfoContPaddingH: var(--sp8x);
		--hsInfoContBottom: var(--sp4x);
		--hsTitleMaxWidth: 51.718vw;
		--hsAdContInset: auto var(--hsInfoContPaddingH) var(--hsInfoContBottom) auto;
		--hsAdInfoContMaxWidth: 28.75vw;
		--hsAdInfoContPadding: var(--sp4x);
		--hsAdInfoIconMarginLeft: var(--sp3x);
		--hsAdCloseIconContSize: var(--sp5x);
		--hsAdCloseIconMargin: 0 0 0 var(--sp2x);
		--hsAdCloseIconSize: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--hsProportion: 57.81%;
		--hsInfoContPaddingH: var(--sp6x);
		--hsInfoContBottom: var(--sp6x);
		--hsTitleMaxWidth: 51.85vw;
		--hsAdContInset: auto var(--hsInfoContPaddingH) var(--hsInfoContBottom) auto;
		--hsAdInfoContMaxWidth: 28.71vw;
		--hsAdInfoContPadding: var(--sp2x) var(--sp3x);
		--hsAdInfoIconMarginLeft: var(--sp2x);
		--hsAdCloseIconContSize: var(--sp5x);
		--hsAdCloseIconMargin: 0 0 0 var(--sp2x);
		--hsAdCloseIconSize: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--hsProportion: 112.5%;
		--hsInfoContPaddingH: var(--sp6x);
		--hsInfoContBottom: var(--sp6x);
		--hsTitleMaxWidth: 100%;
		--hsAdContInset: calc(var(--sp8x) + var(--hsInfoContBottom)) var(--hsInfoContPaddingH) auto auto;
		--hsAdInfoContMaxWidth: 42.05vw;
		--hsAdInfoContPadding: var(--sp2x) var(--sp3x);
		--hsAdInfoIconMarginLeft: var(--sp2x);
		--hsAdCloseIconContSize: var(--sp5x);
		--hsAdCloseIconMargin: 0 0 0 var(--sp2x);
		--hsAdCloseIconSize: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--hsProportion: 154.58%;
		--hsInfoContPaddingH: var(--sp2x);
		--hsInfoContBottom: var(--sp2x);
		--hsTitleMaxWidth: 100%;
		--hsAdContInset: calc(var(--sp8x) + var(--hsInfoContBottom)) var(--hsInfoContPaddingH) auto var(--hsInfoContPaddingH);
		--hsAdInfoContMaxWidth: 100%;
		--hsAdInfoContPadding: var(--sp2x) var(--sp3x);
		--hsAdInfoIconMarginLeft: var(--sp2x);
		--hsAdCloseIconContSize: var(--sp5x);
		--hsAdCloseIconMargin: 0 0 var(--sp2x) 0;
		--hsAdCloseIconSize: var(--sp2x);

		.hs-ad-cont {
			flex-direction: column-reverse;
			margin: 0 auto;
		}
	}
`

export default HeroSectionStyle
