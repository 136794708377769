import { forwardRef, useEffect, useState, useRef, useImperativeHandle, useCallback } from 'react'
import { gsap } from 'gsap'

//* Components
import Icon from 'components/common/Icon'

//* Style
import VideoStyle from './style'

const CustomVideo = forwardRef((props, ref) => {
    //! State
    const [check, setCheck] = useState(true)
    const [isPlaying, setIsPlaying] = useState(false)

    //! Ref
    const videoContRef = useRef()
    const videoRef = useRef()
    const playButton = useRef()

    useImperativeHandle(
        ref,
        () => ({
            contRef: videoContRef.current,
            video: videoRef.current,
        }),
        []
    )

    useEffect(() => {
        if (!props.autoPlay) {
            setCheck(props.autoPlay)
        }
    }, [props.autoPlay])

    const handleVideoButton = useCallback(() => {
        if (!props.autoPlay) {
            setIsPlaying(!isPlaying)

            gsap.fromTo(playButton.current, { scale: 1, transition: 0.001 }, { scale: 0.7 })
            gsap.fromTo(playButton.current, { scale: 0.7, transition: 0.001 }, { scale: 1 })

            if (isPlaying) {
                videoRef.current.pause()
            } else {
                videoRef.current.play()
            }
        }
    }, [isPlaying])

    return (
        <VideoStyle
            ref={videoContRef}
            className={`video-cont ${isPlaying ? 'playing' : ''} ${props.className || ''}`}
            onClick={handleVideoButton}
        >
            <video
                ref={videoRef}
                autoPlay={check}
                muted={check}
                loop={props.loop}
                controls={props.controls}
                playsInline
            >
                <source
                    src={props.src}
                    type='video/mp4'
                />
            </video>
            {!props.autoPlay && (
                <span
                    ref={playButton}
                    className='play-button'
                >
                    <Icon
                        className={'button-icon'}
                        name={isPlaying ? 'pause' : 'play'}
                    />
                </span>
            )}
        </VideoStyle>
    )
})

CustomVideo.displayName = 'CustomVideo'

export default CustomVideo
