import styled from 'styled-components'

const BookingSectionStyle = styled.section`
	--bosIconSize: var(--sp39x);
	--bosItemsPaddingH: var(--contPaddingLR);
	--bosItemsPaddingV: var(--distanceM1);

	&:not(.last) {
		padding-bottom: var(--distanceL1);
	}

	display: grid;
	grid-template-columns: repeat(2, 1fr);

	.bos-icon-cont {
		grid-column: 1 / 2;
		grid-row: 1 / 3;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 var(--bosItemsPaddingH);

		.bos-icon {
			font-size: var(--bosIconSize);
		}
	}

	.bos-title-cont {
		grid-column: 2 / 3;
		grid-row: 1;
		padding: var(--bosItemsPaddingV) var(--bosItemsPaddingH);
	}

	.bos-button {
		grid-column: 2 / 3;
		grid-row: 2;
		padding: var(--bosItemsPaddingV) var(--bosItemsPaddingH);
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: var(--chromeGreen);
		color: var(--green100);
		transition: all var(--trTime);

		@media (hover: hover) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			&:hover {
				background-color: var(--red100);
				color: var(--white);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--bosIconSize: var(--sp30x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--bosIconSize: var(--sp22x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--bosIconSize: var(--sp21x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--bosIconSize: var(--sp15x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--bosIconSize: var(--sp14x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--bosIconSize: var(--sp8x);

		display: grid;
		grid-template-columns: repeat(12, 1fr);

		.bos-icon-cont {
			grid-column: 1 / 4;
		}

		.bos-title-cont {
			grid-column: 4 / 13;
		}

		.bos-button {
			grid-column: 4 / 13;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--bosIconSize: var(--sp6x);

		display: grid;
		grid-template-columns: repeat(4, 1fr);

		.bos-icon-cont {
			grid-column: 1 / 2;
			grid-row: 1 / 2;
		}

		.bos-title-cont {
			grid-column: 2 / 5;
			grid-row: 1 / 2;
		}

		.bos-button {
			grid-column: 1 / 6;
			grid-row: 2 / 3;
		}
	}
`

export default BookingSectionStyle
