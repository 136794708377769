import styled from 'styled-components'

const SectorCardStyle = styled.div`
    --scPadding: var(--sp6x);
    --scBottomInfoPaddingBottom: var(--scPadding);
    --scTitleMarginBottom: var(--sp5x);
    --scNumberMarginBottom: var(--sp3x);
    --scIconSize: var(--sp25x);

    --scBottomInfoPosition: absolute;
    --scBottomInfoInset: 0 0 0 auto;

    height: 100%;

    .sc-cont {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: var(--scPadding);
        background: var(--white);
        transition: background var(--trTime);

        .sc-title {
            color: var(--textBlack100);
            margin-bottom: var(--scTitleMarginBottom);
        }

        .sc-info-cont {
            position: relative;
            flex: 1;
            display: flex;
            flex-direction: column;

            .sc-description {
                flex: 1;
                padding-bottom: var(--scBottomInfoPaddingBottom);
            }

            .sc-bottom-cont {
                padding-top: var(--scBottomInfoPaddingBottom);

                .sc-bottom-info {
                    position: var(--scBottomInfoPosition);
                    inset: var(--scBottomInfoInset);
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    padding-bottom: var(--scBottomInfoPaddingBottom);

                    .sc-number {
                        margin-bottom: var(--scNumberMarginBottom);
                    }
                }

                .sc-icons-cont {
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;

                    .sc-images-cont {
                        position: relative;
                        width: var(--scIconSize);
                        height: var(--scIconSize);

                        .sc-hover-image {
                            position: static;
                            inset: 0;
                        }

                        .image-cont {
                            --proportion: 100%;

                            img {
                                object-fit: contain;
                                object-position: bottom;
                            }
                        }
                    }
                }
            }
        }

         //! Hover Animations
        @media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXS}) {
            @media (hover: hover) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
                .sc-text-anim {
                    color: var(--textBlack100);
                    transition: color var(--trTime);
                }

                .sc-opacity-anim {
                    opacity: 0;
                }

                .sc-opacity-reverse-anim {
                    opacity: 1;
                }

                .ena-agri {
                    .path2:before {
                        transition: color var(--trTime);
                    }
                }

                &:hover {
                    background: var(--green100);

                    .sc-text-anim {
                        color: var(--white);
                    }

                    .sc-opacity-anim {
                        opacity: 1;
                        transition: opacity var(--trTime);
                    }

                    .sc-opacity-reverse-anim {
                        opacity: 0;
                        transition: opacity var(--trTime);
                    }

                    .ena-agri {
                        .path2:before {
                            color: var(--white);
                        }
                    }
                }
            }
        }
        //! End Hover Animations
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--scPadding: var(--sp4x);
        --scBottomInfoPaddingBottom: var(--scPadding);
        --scTitleMarginBottom: var(--sp4x);
        --scNumberMarginBottom: var(--sp2x);
        --scIconSize: var(--sp20x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--scPadding: var(--sp4x);
        --scBottomInfoPaddingBottom: var(--scPadding);
        --scTitleMarginBottom: var(--sp4x);
        --scNumberMarginBottom: var(--sp2x);
        --scIconSize: var(--sp18x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
        --scPadding: var(--sp4x);
        --scBottomInfoPaddingBottom: var(--scPadding);
        --scTitleMarginBottom: var(--sp4x);
        --scNumberMarginBottom: var(--sp2x);
        --scIconSize: var(--sp16x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--scPadding: var(--sp3x);
        --scBottomInfoPaddingBottom: var(--scPadding);
        --scTitleMarginBottom: var(--sp3x);
        --scNumberMarginBottom: var(--sp1-5x);
        --scIconSize: var(--sp11x);
	}

    @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXSMin}) {
        --scBottomInfoPosition: relative;
        --scBottomInfoInset: auto;

        .sc-cont .sc-info-cont .sc-bottom-cont {
            margin: 0 calc(-1 * var(--scPadding)) calc(-1 * var(--scPadding));
            padding-left: var(--scPadding);
            padding-right: var(--scPadding);
            padding-bottom: var(--scPadding);
            background: var(--green100);
            border-bottom-left-radius: var(--radiusM);
            border-bottom-right-radius: var(--radiusM);
        }

        .ena-agri .path2:before {
            color: var(--white);
        }
    }

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
        --scPadding: var(--sp6x);
        --scBottomInfoPaddingBottom: var(--scPadding);
        --scTitleMarginBottom: var(--sp3x);
        --scNumberMarginBottom: var(--sp1x);
        --scIconSize: var(--sp11x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--scPadding: var(--sp3x);
        --scBottomInfoPaddingBottom: var(--sp3x);
        --scTitleMarginBottom: var(--sp3x);
        --scNumberMarginBottom: var(--sp1x);
        --scIconSize: var(--sp11x);

        .sc-cont {
            padding-top: var(--sp4x);
            padding-bottom: var(--sp4x);
        }
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--scPadding: var(--sp3x);
        --scBottomInfoPaddingBottom: var(--sp3x);
        --scTitleMarginBottom: var(--sp2x);
        --scNumberMarginBottom: var(--sp1x);
        --scIconSize: var(--sp9x);
    }

`

export default SectorCardStyle