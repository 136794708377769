import { memo as Memo } from 'react'

//* Components
import Container from 'components/common/Container'
import Text from 'components/common/Text'
import SectorCard from './SectorCard'

//* Styles
import WhereToInvestSectionStyle from './style'

const WhereToInvestSection = Memo(({ title, text, sectors }) => {
    return (
        <WhereToInvestSectionStyle className="bgGray-bg">
            <Container row className="wti-top-cont">
                <div className="col-6 col-ts-12">
                    <Text tag={'h2'} className="wti-title h2 font-montserrat">
                        {title}
                    </Text>
                </div>
                <div className="col-6 col-ts-12">
                    <Text tag={'h3'} className="wti-text h6 font-montserrat">
                        {text}
                    </Text>
                </div>
            </Container>

            <Container row className="wti-sectors">
                {sectors.map((s, k) => (
                    <div key={k} className="wti-item col-3 col-t-6 col-m-12">
                        <SectorCard {...s} />
                    </div>
                ))}
            </Container>

        </WhereToInvestSectionStyle>
    )
})

export default WhereToInvestSection